<template>
  <div class="HomeChart">
    <!-- <keep-alive> -->
    <TianDiMap class="bm-container" :allData="allData" :lineTool="lineTool" :planeTool="planeTool" :rectangleTool="rectangleTool" :circleTool="circleTool" :lng="center.lng" :lat="center.lat"> </TianDiMap>
    <!-- </keep-alive> -->

    <div class="chartBoxLeft">
      <div class="collect">
        <div class="cont">
          <v-chart @click="chart(e)" ref="barChart" :option="option1" :autoresize="true"  />
        </div>
      </div>
      <div class="collect">
        <div class="cont">
          <v-chart @click="chart(e)" ref="barChart" :option="option2" :autoresize="true"  />
        </div>
      </div>
      <div class="collect">
        <div class="cont">
          <v-chart @click="chart(e)" ref="barChart" :option="option3" :autoresize="true"  />
        </div>
      </div>
    </div>
    <div class="text_box_top">
      <div>
        <span>主机数量:{{ num1 }}</span> <span>节点数量:{{ num2 }}</span>
      </div>
    </div>
  </div>
</template>

<script>
const allData = []
const TianDiMap = () => ({
  component: import('@/components/TianDiMap'),
})

// import TianDiMap from '@/components/TianDiMap'
import { findAllTask, findAllTaskByCompanyUser, findAllTaskByHostUser, findAllTaskByUser } from '@/api/Task.js'
import { findMonthAlarm, findTaskSafeSum, findTaskStateSum, findHostAndNodeSum } from '@/api/User.js'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/toolbox'
import 'echarts/theme/macarons'

export default {
  name: 'HomeChart',
  data() {
    return {
      currentPage: 0,
      pageSize: 9999,
      allData,
      lineTool: 0,
      rectangleTool: 0,
      circleTool: 0,
      planeTool: 0,
      center: { lng: 113.27, lat: 23.13 },

      option1: {},
      option2: {},
      option3: {},
      num1: 0,
      num2: 0,
    }
  },
  components: { TianDiMap },
  methods: {
    async findTaskByUser() {
      this.loading = true
      if (this.$store.state.power === 4) {
        return await findAllTask(this.projectName, this.currentPage, this.pageSize)
      }
      if (this.$store.state.power === 3) {
        return await findAllTaskByCompanyUser(this.projectName, this.$store.state.unitId, this.currentPage, this.pageSize)
      }
      if (this.$store.state.power === 2) {
        return await findAllTaskByHostUser(this.projectName, this.$store.state.userId, this.currentPage, this.pageSize)
      }
      if (this.$store.state.power === 1) {
        return await findAllTaskByUser(this.projectName, this.$store.state.userId, this.currentPage, this.pageSize)
      }
    },
    async findAllProject() {
      const { data: res } = await this.findTaskByUser()
      console.log('任务数据', res.data.list)

      this.allData = res.data.list

      this.allData.forEach((item) => {
        item.latitude ? item.latitude : 0
        item.longitude ? item.longitude : 0
        const position = {
          lat: item.latitude || 0,
          lng: item.longitude || 0,
        }
        item.position = position
        item.show = false
        item.hostNumber = item.host.hostNumber
        item.company ? (item.companyName = item.company.companyName) : ''
        item.state ? (item.runningState = item.state.runningState) : ''
        item.safeState ? (item.safeState = item.safeState.safeState) : ''
      })
      console.log('处理完的数据', this.allData)
    },
    async findMonthAlarmFun() {
      const { data: res } = await findMonthAlarm(this.$store.state.userId)
      this.option1 = {
        title: {
          text: '近4月报警数据图',
          textStyle: {
            fontSize: 14,
            color: '#ffffff',
          },
          target: 'self',
          padding: [20, 110],
        },
        xAxis: {
          type: 'category',
          data: ['报警', '预警'],
          axisLine: {
            lineStyle: {
              color: '#fff',
            },
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#fff',
            },
          },
        },
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            data: [
              {
                value: res.data.alarm,

                itemStyle: {
                  color: '#D82300',
                },
              },
              {
                value: res.data.warning,
                itemStyle: {
                  color: '#FFDA00',
                },
              },
            ],
            type: 'bar',
          },
        ],
      }
    },
    async findTaskSafeSumFun() {
      const { data: res } = await findTaskSafeSum(this.$store.state.userId)
      console.log('预警统计', res)
      this.option2 = {
        title: {
          text: '工程预警情况统计',
          textStyle: {
            fontSize: 14,
            color: '#ffffff',
          },
          target: 'self',
          padding: [20, 110],
        },
        color: ['#23DA8B', '#FFDA00', '#D82300'],
        legend: {
          left: 'center',
          bottom: 10,
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF',
          },
        },
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            type: 'pie',
            radius: '50%',
            data: [
              { value: res.data.normal, name: '安全', label: { color: '#FFF' } },
              { value: res.data.warning, name: '预警', label: { color: '#FFF' } },
              { value: res.data.alarm, name: '报警', label: { color: '#FFF' } },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }
    },
    async findTaskStateSumFun() {
      const { data: res } = await findTaskStateSum(this.$store.state.userId)
      console.log('运行统计', res)
      this.option3 = {
        title: {
          text: '工程运行情况统计',
          textStyle: {
            fontSize: 14,
            color: '#ffffff',
          },
          target: 'self',
          padding: [20, 110],
        },
        color: ['#07A0E4', '#FAC858', '#23DA8B'],
        legend: {
          left: 'center',
          bottom: 10,
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF',
          },
        },
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            type: 'pie',
            radius: '50%',
            data: [
              { value: res.data.register, name: '登记', label: { color: '#FFF' } },
              { value: res.data.underway, name: '运行中', label: { color: '#FFF' } },
              { value: res.data.complete, name: '完工', label: { color: '#FFF' } },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }
    },
    async findHostAndNodeSumFun() {
      const { data: res } = await findHostAndNodeSum(this.$store.state.userId)
      this.num1 = res.data.Host
      this.num2 = res.data.Node
    },

    // 图表展示
  },
  created() {
    this.findAllProject()
    this.findMonthAlarmFun()
    this.findTaskSafeSumFun()
    this.findTaskStateSumFun()
    this.findHostAndNodeSumFun()
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.HomeChart {
  width: 100%;
  height: 100%;
  position: absolute;

  overflow: hidden;

  .text_box_top {
    position: absolute;
    z-index: 2000;
    width: 400px;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    // background-color: rgba(0, 76, 110, 0.6);
    color: #fff;
    text-align: center;
    border-top: 50px solid rgba(0, 76, 110, 0.6);
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    > div {
      position: absolute;
      width: 400px;
      height: 50px;
      line-height: 50px;
      top: -50px;
      left: 0;
      text-align: center;
      span {
        font-size: 16px;
        color: #fff;
        padding: 0 30px;
      }
    }
  }

  .chartBoxLeft {
    width: 330px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 10px;
    z-index: 1000;
    padding: 10px;
    .collect {
      margin-bottom: 10px;
      height: 33%;
      width: 100%;
      border-radius: 10px;
      background: url('../../assets/image/border_bottom_right.png') no-repeat bottom right, url('../../assets/image/border_top_left.png') no-repeat top left;
      position: relative;
      background-size: 50% 50%;
      background-color: rgba(0, 76, 110, 0.6);
      color: #fff;
      .cont{
        width: 320px; 
        height: 100%;
        padding-left: 10px; padding-top: 0px; color: #ffffff;
      }
    }
  }
}
</style>
